import React, { useRef, useEffect } from 'react';
import * as THREE from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
// import * as dat from 'dat.gui'
import gsap from 'gsap';
// import text from "./content.json";

import Amplify from 'aws-amplify';
import awsExports from "./aws-exports";

import './App.css';

Amplify.configure(awsExports);

const App = () => {

  // const [view, setView] = useState('all');
  // const types = ['logo', 'software', 'hardware', 'music', 'about', 'line1', 'line2', 'line3'];
  // const slidePercentage = {
  //   software: '-230%',
  //   hardware: '-480%',
  //   music: '-710%',
  //   about: '-720%'
  // }

  // const transition = selectType => {
  //   if (view === 'all') {
  //     const typeElements = types.reduce((at, t) => ({...at, [t]: document.getElementById(t)}), {});
      
  //     types.forEach(type => {
  //       if (type !== selectType) {
  //         typeElements[type].style.opacity = 0;
  //         setTimeout(() => typeElements[type].style.visibility = 'hidden', 1500);
  //       }
  //       else {
  //         setTimeout(() => typeElements[type].style.transform = `translate(0px, ${slidePercentage[type]})`, 1000);
  //       }
  //     });
  //     setTimeout(() => {
  //       setView(selectType);
  //       document.getElementById('back').style.transition = 'opacity 1.5s';
  //       setTimeout(() => document.getElementById('back').style.opacity = 1, 10);
  //       document.getElementById('back').style.transition = 'transform 3s, opacity 0.5s';
  //       setTimeout(() => document.getElementById('content').style.opacity = '1', 10);
  //       setTimeout(() => document.getElementById('content').style.transform = 'rotateX(0deg)', 10);
  //     }, 1500)
  //   }
  // }

  // const back = () => {
  //   if (view !== 'all') {
  //     document.getElementById('content').style.transform = 'rotateX(90deg)';
  //     setTimeout(() => document.getElementById('content').style.opacity = '0', 500);
  //     setTimeout(() => {
  //       const typeElements = types.reduce((at, t) => ({...at, [t]: document.getElementById(t)}), {});
  //       document.getElementById('back').style.opacity = 0;
  //       types.forEach(type => {
  //         if (type !== view) {
  //           typeElements[type].style.visibility = 'visible';
  //           setTimeout(() => typeElements[type].style.opacity = 0.9, 1000);
  //         }
  //         else {
  //           typeElements[type].style.transform = `translate(0px, 0px)`;
  //         } 
  //       });
  //       setTimeout(() => {
  //         setView('all');
  //       }, 1500)
  //     }, 1800);
  //   }
  // }

const currentDevice = useRef('desktop')


// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();
scene.background = new THREE.Color(0x083863);

const pointLight1 = new THREE.PointLight(0xffffff, 0.3, 0, 2);
pointLight1.position.x = 70;
pointLight1.position.y = 10;
pointLight1.position.z = 100;
scene.add(pointLight1);

const pointLight2 = new THREE.PointLight(0xffffff, 0.2, 0, 2);
pointLight2.position.x = 0;
pointLight2.position.y = 0;
pointLight2.position.z = 100;
scene.add(pointLight2);

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight
  // Update camera
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize( window.innerWidth, window.innerHeight );
});

const loader = new GLTFLoader();

loader.load('brianhallerlogo.glb', handle_load);
let logoMesh, objs = [];
function handle_load(gltf) {
    gltf.scene.rotation.set(Math.PI / 2, 0,0);
    logoMesh = gltf.scene;
    logoMesh.position.set(0, 5.5, 0);
    logoMesh.scale.set(1, 1, 1);
    logoMesh.children.forEach(child => {
        if (child.isMesh) objs.push(child);
    })
    scene.add(logoMesh);
}

loader.load('githublogo.glb', handle_github_load);
let githubMesh, githubLogoMeshObjs = [];
function handle_github_load(gltf) {
    gltf.scene.rotation.set(Math.PI / 2, 0, 0)
    githubMesh = gltf.scene;
    githubMesh.position.set(-2, 0, 0)
    githubMesh.scale.set(0.25, 0.25, 0.25)
    githubMesh.children.forEach(child => {
      if (child.isMesh) githubLogoMeshObjs.push(child);
    })
    scene.add(githubMesh);
}

loader.load('linkedinlogo.glb', handle_linkedin_load);
let linkedinMesh, linkedinLogoMeshObjs = [];
function handle_linkedin_load(gltf) {
    gltf.scene.rotation.set(Math.PI / 2, 0, 0)
    linkedinMesh = gltf.scene;
    linkedinMesh.position.set(0, 0, 0)
    linkedinMesh.scale.set(0.25, 0.25, 0.25)
    linkedinMesh.children.forEach(child => {
      if (child.isMesh) linkedinLogoMeshObjs.push(child);
    })
    scene.add(linkedinMesh);
}

loader.load('youtubelogo.glb', handle_youtube_load);
let youtubeMesh, youtubeLogoMeshObjs = [];
function handle_youtube_load(gltf) {
    gltf.scene.rotation.set(Math.PI / 2, 0, 0)
    youtubeMesh = gltf.scene;
    youtubeMesh.position.set(2, 0, 0)
    youtubeMesh.scale.set(0.25, 0.25, 0.25)
    youtubeMesh.children.forEach(child => {
      if (child.isMesh) youtubeLogoMeshObjs.push(child);
    })
    scene.add(youtubeMesh);
}

const renderer = new THREE.WebGLRenderer({
  canvas: canvas
});

const geometry = new THREE.PlaneBufferGeometry(12, 7);

const planesDefaultPosition = {
  0: { x: 0, y: 0, z: 0, color: 0xff0000 },
  1: { x: 4, y: 1, z: -0.5, color: 0x00ff00 },
  2: { x: 0, y: 2, z: -1, color: 0xff00ff },
  3: { x: -4, y: 1, z: -0.5, color: 0x0000ff }
}

const zMaps = {
  calculateX: x => {
    console.log("z", x)
    console.log((x / 100) * 4)
   return ((x / 100) * 4)
  },
  calculateZ: z => {
   return ((((100 - z) / 100) * 0.6) + 0.4)
  },
  '0': 1,
  '-0.5': 0.7,
  '-1': 0.4
}

const planes = {}

// for (let i = 0; i < 4; i += 1) {
//   const material = new THREE.MeshBasicMaterial();
//   material.color = new THREE.Color(planesDefaultPosition[i].color);
//   const plane = new THREE.Mesh(geometry, material);
//   plane.position.set(planesDefaultPosition[i].x, planesDefaultPosition[i].y, planesDefaultPosition[i].z)
//   plane.scale.set(plane.scale.x * zMaps[plane.position.z], plane.scale.y * zMaps[plane.position.z]);
//   planes[i] = plane;
//   scene.add(plane)
// }

const camera = new THREE.PerspectiveCamera( 45, sizes.width / sizes.height, 1, 1000 );
camera.position.set(0, 0, 20);
scene.add(camera)

renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const raycaster = new THREE.Raycaster();

const intersectObject = {};
const timers = [];
const inLogos = {};

const mouse = new THREE.Vector2();
mouse.x = -1;
mouse.y = -1;

window.addEventListener('mousemove', (e) => {
    mouse.x = e.clientX / sizes.width * 2 - 1;
    mouse.y = - (e.clientY / sizes.height) * 2 + 1;
});

let total = 0;
window.addEventListener('wheel', (e) => {
    let moveAmt = e.deltaY / 1000;
    console.log(total = ((total + moveAmt) % 1), planes['0'].position.x);
    switch (positionCheck(planes['0'])) {
      case 'frontRight': 
        planes['0'].position.set(total * 4, planes['0'].position.y, planes['0'].position.z);
        planes['0'].scale.set(zMaps.calculateZ(total), zMaps.calculateZ(total));
        break;
      case 'frontLeft': 
        planes['0'].position.set(planes['0'].position.x += total * 4, planes['0'].position.y, planes['0'].position.z);
        planes['0'].scale.set(zMaps.calculateZ(-total), zMaps.calculateZ(-total));
        break;
      default:
        break;
    }
    // console.log(positionCheck(planes['0']));
});

window.addEventListener('click', () => {
  if (inLogos.github) document.getElementById('githublink').click();
  else if (inLogos.linkedin) document.getElementById('linkedinlink').click();
  else if (inLogos.youtube) document.getElementById('youtubelink').click();
});

const positionCheck = mesh => {
  if (mesh.position.x >= 0 && mesh.position.z >= -0.5) return 'frontRight';
  if (mesh.position.x < 0 && mesh.position.z >= -0.5) return 'frontLeft';
}

const resizeLogos = () => {
  console.log(currentDevice.current)
  if (currentDevice.current === 'mobile') {
    logoMesh.scale.set(0.33, 0.33, 0.33);
    logoMesh.position.set(0, 7, 0);
  }
  if (currentDevice.current === 'tablet') {
    logoMesh.scale.set(0.66, 0.66, 0.66);
    logoMesh.position.set(0, 6, 0);
  }
  if (currentDevice.current === 'desktop') {
    logoMesh.scale.set(1, 1, 1);
    logoMesh.position.set(0, 5.5, 0);
  }
}

const tick = () => {

  if (logoMesh) {
    if (sizes.width < 450 && currentDevice.current !== 'mobile') {
      currentDevice.current = 'mobile';
      resizeLogos();
      // document.getElementById('navcontainer').style.backgroundColor = "yellow";
    }
    else if (sizes.width >= 450 && sizes.width < 800 && currentDevice.current !== 'tablet') {
      currentDevice.current = 'tablet';
      resizeLogos();
    }
    else if (sizes.width >= 800 && currentDevice.current !== 'desktop') {
      currentDevice.current = 'desktop';
      resizeLogos();
    }
  }

  raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(objs);
    for (const intersect of intersects) {
      if (intersect.object.rotation.z <= 0) intersect.object.rotation.z = 2 * Math.PI;
      if (!intersectObject[intersect.object.uuid]) {
        gsap.to(intersect.object.position, { y: Math.random() > 0.5 ? 1 : -1, duration: 2 });
        gsap.to(intersect.object.rotation, { x: Math.PI, duration: 2 });
      }
      else {
        clearTimeout(timers[intersect.object.uuid])
      }
      intersectObject[intersect.object.uuid] = true;
      let timer = setTimeout(() => delete intersectObject[intersect.object.uuid], 100);
      timers[intersect.object.uuid] = timer;
    }

    for (const object of objs) {
      if (!intersects.find(intersect => intersect.object === object) && !intersectObject[object.uuid]) {
        gsap.to(object.position, { y: 0, duration: 2 });
        if (object.rotation._x.toFixed(5) === (Math.PI).toFixed(5)) {
          gsap.to(object.rotation, { x: Math.PI * 2, duration: 2 }).then(() => gsap.to(object.rotation, { x: 0, duration: 0 }));
        }
      }
    }

    const githubLogoHit = raycaster.intersectObjects(githubLogoMeshObjs);
    if (githubLogoHit[0]?.object) {
      canvas.style.cursor = 'pointer';
      inLogos.github = true;
      githubMesh.rotation.z += 0.01;
      if (githubMesh.rotation.z >= Math.PI * 2) githubMesh.rotation.z = 0;
    }
    else {
      if (inLogos.github) {
        inLogos.github = false;
        canvas.style.cursor = 'default';
        gsap.to(githubMesh.rotation, { z: 0, duration: 1 })
      }
    }

    const linkedinLogoHit = raycaster.intersectObjects(linkedinLogoMeshObjs);
    if (linkedinLogoHit[0]?.object) {
      canvas.style.cursor = 'pointer';
      inLogos.linkedin = true;
      linkedinMesh.rotation.z += 0.01;
      if (linkedinMesh.rotation.z >= Math.PI * 2) linkedinMesh.rotation.z = 0;
    }
    else {
      if (inLogos.linkedin) {
        inLogos.linkedin = false;
        canvas.style.cursor = 'default';
        gsap.to(linkedinMesh.rotation, { z: 0, duration: 1 })
      }
    }

    const youtubeLogoHit = raycaster.intersectObjects(youtubeLogoMeshObjs);
    if (youtubeLogoHit[0]?.object) {
      canvas.style.cursor = 'pointer';
      inLogos.youtube = true;
      youtubeMesh.rotation.z += 0.01;
      if (youtubeMesh.rotation.z >= Math.PI * 2) youtubeMesh.rotation.z = 0;
    }
    else {
      if (inLogos.youtube) {
        inLogos.youtube = false;
        canvas.style.cursor = 'default';
        gsap.to(youtubeMesh.rotation, { z: 0, duration: 1 })
      }
    }


    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
};

useEffect(tick)

  return (
    <div className="app">
      <a id="githublink" href="https://github.com/brianjhaller" target="_blank" rel="noreferrer"> </a>
      <a id="linkedinlink" href="https://www.linkedin.com/in/brianjhaller/" target="_blank" rel="noreferrer"> </a>
      <a id="youtubelink" href="https://www.youtube.com/channel/UCu9oBdLcSvvB04AWziWiPUg/featured" target="_blank" rel="noreferrer"> </a>
      {/* <div id="navcontainer" style={{position: 'absolute', top: '30%', left: '30%', color: 'white', height: '40%', width: '40%', backgroundColor: '#595959', borderRadius: 12}}><span>wow</span></div> */}
    </div>
  )
}

export default App;

      // <div className="logoContainer">
      //   <img className="logo" id="logo" src="/brianhallerlogo.svg" alt="logo" />
      //   {/* <img className="logoShadow" id="logoShadow" src="/brianhallerlogo.svg" alt="logo" /> */}
      // </div>
      // <div className="nav">
      //   <img onClick={() => view !== 'software' ? transition('software') : back()} className="navLink" id="software" src="/software.svg" alt="software" />
      //   <img className="navLink line" id="line1" src="/hr.svg" alt="logo" />
      //   <img onClick={() => view !== 'hardware' ? transition('hardware') : back()} className="navLink" id="hardware" src="/hardware.svg" alt="hardware" />
      //   <img className="navLink line" id="line2" src="/hr.svg" alt="logo" />
      //   <img onClick={() => view !== 'music' ? transition('music') : back()} className="navLink" id="music" src="/music.svg" alt="music" />
      //   <img className="navLink line" id="line3" src="/hr.svg" alt="logo" />
      //   <img onClick={() => view !== 'about' ? transition('about') : back()} className="navLink" id= "about" src="/about.svg" alt="about" />
      // </div>
      // {view !== 'all' && <div className="content" id="content">
      //   {text[view].content.map((text, i) => (<span key={`text${i}`}>{text}<br /><br /></span>))}
      //   {text[view].pic && <img src={text[view].pic} id="contentImage" alt="content" />}
      // </div>}
      // {view !== 'all' && <img src="/back.svg" onClick={back} id="back" className="back" alt="back" />}